let prefix = "/eventSalesDate"

const EventSalesDateApi = {
	
	getEventSalesDateList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	getEventSalesDateRef(payload){
		return window.Vue.axios.get( prefix + "/list-ref", {params: payload})
	},
	archiveEventSalesDate(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEventSalesDate(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateEventSalesDate(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
}

export default EventSalesDateApi;